interface Size {
  xs: string;
  sm: string;
  md: string;
  lg: string;
}

const size: Size = {
  xs: "480px", // for small screen mobile
  sm: "600px", // for mobile screen
  md: "768px", // for tablets
  lg: "900px", // for tablets
};

export const device = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
};
