import styled from "styled-components";
import { device } from "../breakpoints.ts";
const { xs, sm, md, lg } = device;
export const Container = styled.section`
  position: relative;
  height: 700px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  overflow: hidden;
`;
export const Darken = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: #1d1d1d31;
`;
export const BgVideo = styled.video`
  position: absolute;
  object-fit: fill;
`;

export const TextContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  z-index: 1;
  gap: 40px;
  flex-direction: column;
`;
export const Heading = styled.h1`
  font-family: "Audiowide", sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media ${md} {
    font-size: 12px;
  }
`;
export const Div = styled.div`
  height: 50px;
  display: flex;
  position: relative;
`;
export const TextDiv = styled.div`
  position: absolute;
  top: 0;
  width: 720px;
  clear: both;
  right: -35%;
  transition: 2s ease-in-out all;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: ${({ pos }) =>
    pos === "cur"
      ? "translateX(0%)"
      : pos === "next"
      ? "translateX(100%)"
      : "translateX(-100%)"};
  @media ${lg} {
    right: -22%;
    width: 600px;
  }
  @media ${md} {
    right: -30%;
    width: 500px;
  }
  @media ${xs} {
    right: -15%;

    width: 400px;
  }
`;
export const MainText = styled.p`
  font-family: "Raleway", sans-serif;
  font-size: 48px;
  text-align: center;
  font-weight: 700;
  @media ${lg} {
    font-size: 40px;
  }
  @media ${md} {
    font-size: 32px;
  }
  @media ${xs} {
    font-size: 24px;
  }
`;
export const Motto = styled.p`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 300;
  @media ${md} {
    font-size: 12px;
  }
`;

export const HeroButton = styled.a`
  width: 150px;
  height: 45px;
  margin: 0 auto;
  display: grid;
  align-content: center;
  text-decoration: none;
  color: white;
  border: 3px solid white;
  cursor: pointer;
  background-color: none;
  transition: 0.3s ease-in-out all;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(14, 120, 152, 1) 0%,
      rgba(5, 169, 218, 1) 47%
    );
    background: white;
    color: black;
    font-weight: 500;
    scale: 0.9;
  }
`;
export const ArrowButton = styled.button`
  background-color: transparent;
  border: 1px solid white;
  width: 40px;
  height: 40px;
  position: absolute;
  transition: 0.3s ease-in-out all;
  top: 50%;
  color: white;
  display: grid;
  place-items: center;
  transform: translateY(-50%);
  left: ${({ left }) => (left ? "80px" : "")};
  right: ${({ right }) => (right ? "80px" : "")};
  font-size: 24px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: black;
  }
  @media ${lg} {
    bottom: 50px;
    left: ${({ left }) => (left ? "40%" : "")};
    right: ${({ right }) => (right ? "40%" : "")};
    top: auto;
  }
  @media ${sm} {
    left: ${({ left }) => (left ? "35%" : "")};
    right: ${({ right }) => (right ? "35%" : "")};
  }
`;
