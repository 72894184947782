import {
  AboutImg,
  AboutLeft,
  AboutRight,
  Container,
  Description,
  Heading,
  Icons,
  TextDiv,
} from "./AboutElements";
import {} from "react-icons/fa"
import aboutimg from "../../img/aboutimg.png";
import icon from "../../img/Icons.png"
const About = () => {
  return (
    <Container id="About">
      <AboutLeft>
        <TextDiv>
          <Heading>OUR MISSION</Heading>
          <Description>
            Elevating spaces through innovative design, our architectural firm
            is committed to creating timeless structures that seamlessly blend
            functionality, sustainability, and aesthetic harmony, shaping
            environments that inspire and endure.
          </Description>
        </TextDiv>
        <TextDiv>
          <Heading>OUR VISION</Heading>
          <Description>
            Pioneering a future where architectural excellence converges with
            sustainability, our firm envisions a world transformed by purposeful
            design, where every structure reflects a harmonious balance between
            innovation, environmental consciousness, and the human experience.
          </Description>
        </TextDiv>
        <Icons draggable="false" src={icon} alt=""/>
      </AboutLeft>
      <AboutRight>
        <AboutImg draggable="false" src={aboutimg} alt="" />
      </AboutRight>
    </Container>
  );
};

export default About;
