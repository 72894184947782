import { useState, useEffect } from "react";
import {
  BottomContainer,
  Container,
  Description,
  LeftProgress,
  Location,
  Name,
  Progress,
  ProgressDiv,
  ProgressText,
  ProjectContainer,
  ProjectProgress,
  RightProgress,
  TopContainer,
} from "./ProjectElements";
import { FaCheck, FaCircle } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import img1 from "../../img/clear.png";
import img2 from "../../img/clear2.png";
import img3 from "../../img/clear3.png";

const Project = () => {
  const [progressI, setProgressI] = useState(0);
  // Slider Logic
  useEffect(() => {
    const last = progressData.length - 1;
    if (progressI < 0) {
      setProgressI(last);
    }
    if (progressI > last) {
      setProgressI(0);
    }
  }, [progressI]);

  useEffect(() => {
    let slider = setInterval(() => {
      setProgressI(progressI + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [progressI]);
  return (
    <Container itemScope itemType="https://schema.org/Project" id="Projects">
      <TopContainer>
        <ProjectContainer>
          <Location itemProp="location">SOKA, IBADAN</Location>
          <Name itemProp="name">Mieswood Haven</Name>
          <Description itemProp="description">
            Onil Construct Ltd designed and constructed this two-bedroom ensuite
            semi-detached bungalow in Soka, Ibadan, completing the project
            within 7 months. Working meticulously within budget and timeline,
            and collaborating closely with our client, we transformed their
            dream into reality. Our approach to the project embraced the
            principle of 'less is more,' as coined by Ludwig Mies van der Rohe,
            resulting in simple yet unique design solutions. Despite
            encountering various challenges, we persevered to fulfill our
            client's vision, gaining valuable insights along the way. We extend
            our sincere appreciation to our client for entrusting us
            with this project.
          </Description>
        </ProjectContainer>
        <ProjectProgress>
          <LeftProgress>
            {progressData.map((el, i) => {
              var active;
              var checked;
              if (progressI === i) {
                active = true;
              }
              if (progressI > i) {
                checked = true;
              }
              return (
                <>
                  <ProgressDiv
                    onClick={() => setProgressI(i)}
                    checked={checked}
                    active={active}
                  >
                    {checked && <FaCheck />}
                    {active && <FaCircle />}
                  </ProgressDiv>
                  {i === 0 && <Progress active={progressI}></Progress>}
                </>
              );
            })}
          </LeftProgress>
          <RightProgress>
            {progressData.map((el, i) => {
              var active;
              if (progressI === i) {
                active = true;
              }
              return (
                <ProgressText itemProp="description" i={i} active={active}>
                  {el}
                </ProgressText>
              );
            })}
          </RightProgress>
        </ProjectProgress>
      </TopContainer>
      <BottomContainer>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            <img
              itemProp="image"
              src={img1}
              alt="Two Bedroom ensuite semi-detached bungalow by Onil construct"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              itemProp="image"
              src={img2}
              alt="Two Bedroom ensuite semi-detached bungalow by Onil construct"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              itemProp="image"
              src={img3}
              alt="Two Bedroom ensuite semi-detached bungalow by Onil construct"
            />
          </SwiperSlide>
          <div className="slider-controller">
            <div className="swiper-button-prev slider-arrow">
              <MdKeyboardArrowLeft />
            </div>
            <div className="swiper-button-next slider-arrow">
              <MdKeyboardArrowRight color="white" />
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </BottomContainer>
    </Container>
  );
};

export default Project;

const progressData = [
  "Two Bedroom ensuite semi-detached bungalow",
  "Designed and Constructed within 7 months",
  "Working within Budget and Timeline",
];
