import styled from "styled-components";
import { device } from "../breakpoints.ts";
const { xs, lg } = device;

export const Container = styled.section`
  position: relative;
  height: 700px;
  display: flex;
  align-items: center;
  padding-left: 120px;
  justify-content: space-between;
  overflow: hidden;
  user-select: none;
  border-bottom: 1px #80808031 solid;
  @media ${lg} {
    padding: 100px 40px;
    gap: 40px;
    height: auto;
    flex-direction: column-reverse;
  }
  @media ${xs} {
    padding: 40px;
  }
`;
export const AboutLeft = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  @media ${lg} {
    gap: 40px;
    width: 100%;
  }
`;
export const AboutRight = styled.div`
  width: 45%;
  @media ${lg} {
    width: 100%;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const Heading = styled.h1`
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 6px;
`;
export const Description = styled.p`
  text-align: justify;
  text-justify: inter-word;
  line-height: 30px;
`;

export const Icons = styled.img`
  width: 70%;
  @media ${lg} {
    width: 80%;
  }
`;

// export const Icon = styled.div`
//   /* font-size: ; */
//   width: 40px;
//   height: 40px;
// `;

export const AboutImg = styled.img`
  width: 100%;
`;
