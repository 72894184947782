import styled from "styled-components";
import { device } from "../breakpoints.ts";

const { lg, xs } = device;

export const Container = styled.section`
  padding: 120px 80px;
  /* height: 650px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  @media ${lg} {
    padding: 100px 40px;
  }
`;
export const Logo = styled.img`
  height: 100%;
  left: -240px;
  position: absolute;
  @media (max-width: 1100px) {
    left: -150px;
  }
  @media ${lg} {
    display: none;
  }
`;
export const FormContainer = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  @media (max-width: 1100px) {
    width: 70%;
  }
  @media ${lg} {
    width: 100%;
    text-align: left;
  }
`;

export const FormHeader = styled.h2`
  font-size: 48px;
  font-weight: 300;
  @media ${xs} {
    font-size: 45px;
  }
`;

export const FormDescription = styled.p`
  text-align: justify;
  text-justify: inter-word;
  font-weight: 300;
  letter-spacing: 6%;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.label`
  font-weight: 700;
  text-align: left;
`;
export const Input = styled.input`
  height: 45px;
  outline: none;
  border: none;
  color: grey;
  padding: 20px;
  background-color: rgba(29, 29, 29, 0.3);
  width: 70%;
  &:focus {
    color: white;
    border-bottom: 1px solid white;
  }
`;
export const TextArea = styled.textarea`
  height: 100px;
  width: 70%;
  resize: none;
  outline: none;
  border: none;
  color: grey;
  padding: 20px;
  background-color: rgba(29, 29, 29, 0.3);
  &:focus {
    color: white;
    border-bottom: 1px solid white;
  }
`;
export const FormButton = styled.button`
  height: 45px;
  width: 70%;
  font-weight: 700;
  color: black;
  align-self: flex-end;
  border: none;
  background-color: white;
  cursor: pointer;
  @media ${lg} {
    width: 100%;
  }
`;
export const SocialContainer = styled.div`
  display: flex;
  gap: 50px;

  @media ${lg} {
    align-self: flex-start;
    flex-direction: column;
  }
`;
export const SocialGroup = styled.a`
  display: flex;
  gap: 20px;
  text-decoration: none;
  transition: 0.3s ease-in-out all;
  cursor: pointer;
  align-items: center;
  &:hover {
    scale: 0.9;
    div {
      svg {
        transform: rotateZ(360deg);
        transition: 0.5s ease-in all;
      }
    }
  }
`;
export const IconDiv = styled.div`
  width: 50px;
  background-color: white;
  height: 50px;
  display: grid;
  color: white;
  place-items: center;
  border-radius: 50%;
`;
export const IconP = styled.p`
  font-weight: 700;
  color: white;
`;
