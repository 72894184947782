import styled from "styled-components";
import { device } from "../breakpoints.ts";
const { md } = device;
export const Container = styled.section`
  background-color:white;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  padding-top: 80px;
`;
export const Top = styled.div`
  display: flex;
  width: 90%;
  gap: 80px;
  flex-wrap: wrap;
  @media ${1100} {
    gap: 40px 40px;
  }
`;
export const Tape = styled.div`
  display: flex;
  width: 90%;
  color:black;
  padding: 20px 0;
  border-top: 1px solid #80808031;
`;

export const Logo = styled.img`
  width: 200px;
  height: 100%;
`;
export const Group = styled.ul`
  display: flex;
  width: ${({ wid }) => wid};
  flex-direction: column;
  gap: 20px;
  list-style: none;
  @media ${md} {
    width: 70%;
  }
`;
export const Heading = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: black;
`;
export const Item = styled.li`
  color: black;
  cursor: text;
  transition: 0.5s ease-in-out all;
  position: relative;
`;

export const LinkItem = styled.a`
  color: rgb(94, 94, 94);
  cursor: pointer;
  transition: 0.5s ease-in-out all;
  /* text-decoration: none; */
  &:hover {
    margin-left: 10px;
    color: black;
  }
`;
