import styled from "styled-components";

import { device } from "../breakpoints.ts";
const { md, sm,xs } = device;

export const Container = styled.section`
  overflow: hidden;
  position: relative;
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  transition: 1s ease-in-out all;
  padding: 80px 120px;
  gap: 80px;
  background-color: black;
  flex-direction: column;
  @media ${md} {
    height: auto;
    flex-direction: column-reverse;
    padding: 80px 40px;
  }
`;
export const TopContainer = styled.div`
  display: flex;
  gap: 5%;
  justify-content: space-between;
  @media (max-width: 1100px) {
    gap: 80px;
    height: auto;
    flex-direction: column;
  }
  @media ${sm} {
    gap: 40px;
  }
`;
export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 25px;
  text-align: left;
  z-index: 1;
  @media (max-width: 1100px) {
    width: 90%;
    text-align: center;
    width: 100%;
  }
`;
export const ProjectProgress = styled.div`
  width: 45%;
  display: flex;
  position: relative;
  background: rgb(235, 235, 235);
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(235, 235, 235, 1) 24%,
    rgba(177, 177, 177, 1) 60%,
    rgba(151, 151, 151, 1) 100%
  );
  border-radius: 10px;
  padding: 60px 40px;
  @media (max-width: 1100px) {
    width: 100%;
  }

  @media ${md} {
    padding: 60px 20px;
  }
`;
export const LeftProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const RightProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-left: 40px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    height: 300px;
  }
  @media ${md} {
    padding-left: 30px;
  }
  @media ${xs} {
    height: 250px;
  }
`;
export const ProgressDiv = styled.div`
  width: 40px;
  transition: 0.5s linear all;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  cursor: pointer;
  border: ${({ checked, active }) =>
    !checked && !active ? "3px solid grey" : ""};
  background-color: ${({ active, checked }) =>
    active || checked ? "black" : "#ebebeb"};
  scale: ${({ active }) => (active ? 1.5 : "")};
`;

export const ProgressText = styled.p`
  font-size: ${({ active }) => (active ? "30px" : "24px")};
  transition: 0.5s linear all;
  font-weight: 700;
  margin-bottom: ${({ i }) => (i === 2 ? "-10px" : "")};
  color: ${({ active }) => (active ? "rgb(0,0,0)" : "rgba(0,0,0,0.5)")};
  @media ${md} {
    font-size: ${({ active }) => (active ? "18px" : "18px")};
    margin-bottom: 0;
    margin-top: ${({ i }) => (i === 2 ? "-10px" : "")};
  }
  @media ${sm} {
  }
`;
export const Progress = styled.div`
  position: absolute;
  width: 5px;
  z-index: 0;
  top: 0;
  left: 59px;
  height: 100%;
  background-color: grey;
  &::before {
    position: absolute;
    background-color: black;
    content: "";
    transition: 0.5s linear all;
    width: 100%;
    height: ${({ active }) =>
      active === 0 ? "20%" : active === 1 ? "50%" : "100%"};
  }
  @media ${md} {
    left: 39px;
  }
`;

export const BottomContainer = styled.div``;

export const Location = styled.p`
  font-size: 20px;
  letter-spacing: 8px;
`;
export const Name = styled.h2`
  font-size: 48px;
  font-family: "Audiowide", sans-serif;
`;
export const Description = styled.p`
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 500;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;
