import {
  Logo,
  Menu,
  Nav,
  NavCenter,
  NavContainer,
  NavLeft,
  NavLink,
  Wording,
} from "./NavbarElements";
import oniltext from "../../img/wording.png";
import onillogo from "../../img/LOGO.png";
import { useEffect, useState } from "react";
// import { HashLink } from "react-router-hash-link";
import { animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  // Navbar Change on Scroll
  useEffect(() => {
    window.addEventListener("scroll", changeBackground, { passive: true });
  }, []);
  const [isScrolling, setIsScrolling] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 5) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };
  return (
    <Nav scroll={isScrolling}>
      <NavContainer>
        <NavLeft>
          <Wording
            onClick={() => scroll.scrollToTop()}
            draggable="false"
            src={oniltext}
            alt="onil construct"
          />
        </NavLeft>
        <NavCenter>
          <Logo
            onClick={() => scroll.scrollToTop()}
            draggable="false"
            src={onillogo}
            alt="onil construct"
          />
        </NavCenter>
        <Menu>
          <NavLink
            to="Home"
            smooth={true}
            offset={-80}
            duration={1500}
            exact="true"
            spy={true}
            activeClass="activelink"
          >
            Home
          </NavLink>
          <NavLink
            to="Services"
            smooth={true}
            offset={-80}
            duration={1500}
            exact="true"
            spy={true}
            activeClass="activelink"
          >
            Services
          </NavLink>
          <NavLink
            to="Projects"
            smooth={true}
            offset={-80}
            duration={1500}
            exact="true"
            spy={true}
            activeClass="activelink"
          >
            Projects
          </NavLink>
          <NavLink
            to="Contact"
            smooth={true}
            // offset={-100}
            duration={1500}
            exact="true"
            spy={true}
            activeClass="activelink"
          >
            Contact us
          </NavLink>
        </Menu>
      </NavContainer>
    </Nav>
  );
};

export default Navbar;
