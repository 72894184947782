import { Link as LinkS } from "react-scroll";
import styled from "styled-components";
import { device } from "../breakpoints.ts";
const { md, sm } = device;

export const Nav = styled.nav`
  height: 80px;
  background-color: ${({ scroll }) => (scroll ? "grey" : "#23232380")};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  user-select: none;
  width: 100%;
  z-index: 10;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  position: relative;
  justify-content: space-between;
  max-width: 1200px;
  @media ${sm} {
    justify-content: center;
  }
`;
export const NavLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const NavCenter = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;

  @media (max-width: 1100px) {
    display: none;
  }
`;
export const Wording = styled.img`
  width: 207px;
  cursor: pointer;
  @media ${md} {
    width: 180px;
  }
`;

export const Logo = styled.img`
  width: 80px;
  cursor: pointer;
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0 30px;
  background-color: #151515d0;
  height: 40px;
  border-radius: 10px;
  @media ${md} {
    gap: 30px;
  }
  @media ${sm} {
    position: absolute;
    left: 50%;
    gap: 40px;
    transform: translateX(-50%);
    bottom: -50px;
  }
`;
export const NavLink = styled(LinkS)`
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
`;
