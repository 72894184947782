import { useEffect, useState } from "react";
import {
  Bottom,
  BottomImg,
  Container,
  Heading,
  ServiceDescription,
  ServiceName,
  ServiceSlider,
  ServiceTop,
  ServicesLeft,
  ServicesRight,
  SingleService,
  Top,
  TopImg,
} from "./ServicesElements";
import img1 from "../../img/Img1.png";
import img2 from "../../img/Img2.png";
import img3 from "../../img/Img3.png";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

const Services = () => {
  const [active, setActive] = useState(0);
  // Slider Logic
  useEffect(() => {
    const last = data.length - 1;
    if (active < 0) {
      setActive(last);
    }
    if (active > last) {
      setActive(0);
    }
  }, [active]);

  useEffect(() => {
    let slider = setInterval(() => {
      setActive(active + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [active]);
  return (
    <Container id="Services">
      <ServicesLeft>
        <Top>
          <TopImg
            draggable="false"
            //   style={{transform:"translateY(-40px)"}}
            src={img1}
            alt=""
          />{" "}
          <TopImg draggable="false" src={img2} alt="" />
        </Top>
        <Bottom>
          <BottomImg draggable="false" src={img3} alt="" />
        </Bottom>
      </ServicesLeft>
      <ServicesRight itemScope itemType="https://schema.org/ProductGroup">
        <Heading itemProp="name">OUR SERVICES</Heading>
        <ServiceSlider>
          {data.map((elm, i) => {
            const { fletter, cletters, description } = elm;
            return (
              <SingleService
                itemScope
                itemType="https://schema.org/Service"
                itemID={i + 1}
                active={active === i}
              >
                <ServiceTop>
                  <MdKeyboardArrowLeft onClick={() => setActive(active - 1)} />
                  <ServiceName itemProp="name">
                    <p>{fletter}</p>
                    {cletters}
                  </ServiceName>
                  <MdKeyboardArrowRight onClick={() => setActive(active + 1)} />
                </ServiceTop>

                <ServiceDescription itemProp="description">
                  {description}
                </ServiceDescription>
              </SingleService>
            );
          })}
        </ServiceSlider>
      </ServicesRight>
    </Container>
  );
};

export default Services;

const data = [
  {
    fletter: "A",
    cletters: "rchitecture",
    description:
      "Our architectural services encompass a broad spectrum, from conceptualization to execution. We believe in creating designs that not only meet the functional needs of our clients but also inspire and elevate the human experience. Our team of talented architects combines creativity with technical expertise to craft bespoke solutions tailored to each project's unique requirements. Whether it's residential, commercial, institutional, or industrial architecture, we strive to push the boundaries of design while ensuring sustainability and efficiency.",
  },
  {
    fletter: "B",
    cletters: "uilding Construction",
    description:
      "At Onil Construct, we are adept at turning architectural visions into tangible structures. With a focus on quality craftsmanship and adherence to timelines, we undertake building construction projects of varying scales and complexities. From ground-breaking to finishing touches, our meticulous approach ensures that every detail is executed with precision, resulting in superior outcomes that exceed expectations.",
  },
  {
    fletter: "D",
    cletters: "evelopment",
    description:
      "As a forward-thinking firm, we actively engage in development projects that contribute to the growth and enhancement of communities. Whether it's revitalizing urban areas, creating sustainable housing solutions, or reimagining public spaces, we approach each development endeavor with a strategic mindset and a commitment to fostering positive change. Our holistic approach considers economic, social, and environmental factors to create developments that stand the test of time.",
  },
  {
    fletter: "I",
    cletters: "nteriors",
    description:
      "Our interior design services are tailored to enhance the functionality, aesthetics, and comfort of interior spaces. Whether it's residential, commercial, or hospitality interiors, we collaborate closely with clients to understand their preferences and aspirations. From concept development to material selection and final installation, our interior designers bring creativity and expertise to transform spaces into reflections of our clients' lifestyles and identities.",
  },
  {
    fletter: "R",
    cletters: "eal Estate",
    description:
      "With our in-depth knowledge of the real estate market, we offer comprehensive services to clients seeking investment opportunities or property solutions. Whether it's identifying prime locations, conducting feasibility studies, or facilitating transactions, our real estate experts provide invaluable insights and support every step of the way. We prioritize transparency, integrity, and client satisfaction, ensuring that each real estate transaction is seamless and rewarding.",
  },
];
