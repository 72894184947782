import styled from "styled-components";
import { device } from "../breakpoints.ts";
const { xs, sm, lg } = device;
export const Container = styled.section`
  height: 650px;
  padding: 120px 40px;
  display: flex;
  gap: 5%;
  align-items: center;
  user-select: none;
  justify-content: center;
  @media (max-width: 1100px) {
    gap: 8%;
  }
  @media ${lg} {
    padding: 80px;
    gap: 40px;
    height: auto;
    flex-direction: column;
  }
  @media ${sm} {
    padding: 80px 40px;
  }
`;

export const ServicesLeft = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  @media ${lg} {
    width: 100%;
  }
  @media ${sm} {
    gap: 20px;
  }
`;
export const Top = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  @media ${lg} {
    gap: 20px;
    justify-content: space-between;
  }
`;
export const TopImg = styled.img`
  width: 200px;
  @media ${lg} {
    width: 47%;
  }
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  @media ${lg} {
    width: 100%;
  }
`;
export const BottomImg = styled.img`
  width: 440px;
  @media ${lg} {
    width: 100%;
  }
`;
export const ServicesRight = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 40px;
  @media (max-width: 1100px) {
    gap: 30px;
  }
  @media ${lg} {
    width: 100%;
    height: 400px;
  }
  @media ${sm} {
    width: 100%;
    height: 500px;
  }
`;
export const Heading = styled.h1`
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 6px;
  width: 100%;
  border-bottom: 2px solid #05a9da;
  padding-bottom: 15px;
`;
export const ServiceSlider = styled.div`
  position: relative;
  @media ${lg} {
    width: 100%;
    justify-content: center;
  }
`;
export const SingleService = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  transition: 1s ease-in-out all;
  opacity: ${({ active }) => (active ? 1 : 0)};
  z-index: ${({ active }) => (active ? 2 : 0)};
  gap: 40px;
  @media (max-width: 1100px) {
    gap: 20px;
  }
`;

export const ServiceTop = styled.div`
  display: flex;
  gap: 20px;
  font-size: 24px;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;
export const ServiceName = styled.h2`
  font-family: "Audiowide", sans-serif;
  font-size: 32px;
  p {
    font-family: "Audiowide", sans-serif;
    display: inline;
    color: #05a9da;
  }
  @media ${sm} {
    font-size: 30px;
  }
  @media ${xs} {
    font-size: 30px;
  }
`;
export const ServiceBottom = styled.div``;
export const ServiceDescription = styled.p`
  text-align: justify;
  text-justify: inter-word;
  line-height: 30px;
`;
