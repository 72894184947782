import {
  Container,
  Group,
  Heading,
  Item,
  LinkItem,
  Logo,
  Tape,
  Top,
} from "./FooterElements";
import logo from "../../img/LOGO.png";

const Footer = () => {
  return (
    <Container>
      <Top>
        <Logo src={logo} alt="" />
        <Group wid="200px">
          <Heading>Contact us</Heading>
          <Item>Info@onilconstruct.com.ng</Item>
          <Item>+2349063782562</Item>
        </Group>
        <Group wid="200px">
          <Heading>Find us Online</Heading>
          <LinkItem
            href="https://www.instagram.com/onil_construct?igsh=MjMyYTE5NHVveXl1&utm_source=qr"
            target="_blank"
          >
            Instagram
          </LinkItem>
          <LinkItem
            href="https://www.facebook.com/profile.php?id=61557150694593"
            target="_blank"
          >
            Facebook
          </LinkItem>
          <LinkItem href="https://wa.link/hdoxww" target="_blank">
            Whatsapp
          </LinkItem>
          <LinkItem
            href="https://ng.linkedin.com/in/onil-construct-1729442b9"
            target="_blank"
          >
            LinkedIn
          </LinkItem>
          <LinkItem href="https://twitter.com/onilconstruct" target="_blank">
            Twitter
          </LinkItem>
        </Group>
        <Group wid="250px">
          <Heading>Our Services</Heading>
          <Item>Building and Civil Contsruction</Item>
          <Item>Architecture Design & Build</Item>
          <Item>Interior Design</Item>
          <Item>Real Estate</Item>
          <Item>Property Development</Item>
        </Group>
      </Top>
      <Tape>©2024 Onil Construct. All Rights Reserved</Tape>
    </Container>
  );
};

export default Footer;
