import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import Services from "../components/Services/Services";
import Project from "../components/Project/Project";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import { Chat, ChatText, Walogo } from "../styles/ChatElements";
import wa from "../img/wa.png";
const Home = () => {
  return (
    <main className="page">
      <Navbar />
      <Hero />
      <Services />
      <Project />
      <About />
      <Contact />
      <Footer />
      <Chat target="_blank" href="https://wa.link/yb94as">
        <ChatText>
          Need Help? <b>Chat with us</b>
        </ChatText>
        <Walogo src={wa} alt="whatsapp logo" />
      </Chat>
    </main>
  );
};

export default Home;
