import { useEffect, useState } from "react";
import {
  ArrowButton,
  BgVideo,
  Container,
  Darken,
  Div,
  Heading,
  HeroButton,
  MainText,
  Motto,
  TextContainer,
  TextDiv,
} from "./HeroElements";
import vid from "../../video/drone.mp4";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
var data = [
  "Structures Beyond Imagination",
  "Designs That Define Eras",
  "Where Form Meets Function",
];
const Hero = () => {
  const [active, setActive] = useState(0);

  // Slider Logic
  useEffect(() => {
    const last = data.length - 1;
    if (active < 0) {
      setActive(last);
    }
    if (active > last) {
      setActive(0);
    }
  }, [active]);

  useEffect(() => {
    let slider = setInterval(() => {
      setActive(active + 1);
    }, 9000);
    return () => {
      clearInterval(slider);
    };
  }, [active]);
  return (
    <Container id="Home">
      <BgVideo playsInline muted autoPlay loop>
        <source type="video/mp4" src={vid} />
        Your browser does not support this video tag
      </BgVideo>
      <Darken></Darken>
      <TextContainer draggable="false">
        <Heading>ARCHITECTURE DESIGN AND CONSTRUCTION</Heading>
        <Div>
          {data.map((text, i) => {
            let pos = "next";
            if (i === active) {
              pos = "cur";
            }
            if (i === active - 1 || (active === 0 && i === data.length - 1)) {
              pos = "prev";
            }

            return (
              <TextDiv pos={pos} active={active === i}>
                <MainText>{text}</MainText>
              </TextDiv>
            );
          })}
        </Div>

        <Motto>blueprint to reality...</Motto>
        <HeroButton target="_blank" href="https://wa.link/yb94as">
          WORK NOW
        </HeroButton>
      </TextContainer>
      <ArrowButton onClick={() => setActive(active + 1)} left={true}>
        <MdKeyboardArrowLeft />
      </ArrowButton>
      <ArrowButton onClick={() => setActive(active - 1)} right={true}>
        <MdKeyboardArrowRight />
      </ArrowButton>
    </Container>
  );
};

export default Hero;
