import {
  Container,
  FormContainer,
  FormDescription,
  FormGroup,
  FormHeader,
  Input,
  Form,
  Label,
  TextArea,
  FormButton,
  Logo,
  SocialContainer,
  SocialGroup,
  IconDiv,
  IconP,
} from "./ContactElements";
import logo from "../../img/logobig.png";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { FaInstagram, FaPhone, FaEnvelope } from "react-icons/fa";

const Contact = () => {
  const form = useRef();
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const handleClick = () => {
    setIsSubmitSuccessful(true);

    setTimeout(() => {
      setIsSubmitSuccessful(false);
    }, 5000);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleClick();
    emailjs
      .sendForm(
        "ID@Onil",
        "TEMPLATE@Onil",
        form.current,
        "wh1aJ6Di1NskiyRQS"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    document.getElementById("form").reset();
  };
  return (
    <Container id="Contact">
      <FormContainer>
        <Logo src={logo} alt="onil construct" />
        <FormHeader>
          Let’s build
          <br /> something <br />{" "}
          <b>
            <p style={{ color: "#069FCE", display: "inline" }}>great</p>{" "}
            together.
          </b>
        </FormHeader>
        <FormDescription>
          We’d love to hear from you! Send us a question, comment or make an
          inquiry with the form below and we would be in touch with you as soon
          as possible
        </FormDescription>
        <Form id="form" ref={form}>
          <FormGroup>
            <Label>FULL NAME</Label>{" "}
            <Input required type="text" name="fullName" />
          </FormGroup>
          <FormGroup>
            <Label>EMAIL</Label> <Input required type="email" name="email" />
          </FormGroup>
          <FormGroup>
            <Label>PHONE</Label> <Input required type="tel" name="phone" />
          </FormGroup>
          <FormGroup>
            <Label>COMPANY</Label> <Input required type="text" name="company" />
          </FormGroup>
          <FormGroup>
            <Label>
              TYPE YOUR <br /> MESSAGE
            </Label>
            <TextArea required type="text" name="message" />
          </FormGroup>
          <FormButton onClick={handleFormSubmit} type="submit">
            SEND MESSAGE
          </FormButton>
        </Form>
        {isSubmitSuccessful && (
          <span>
            Application sent successfully <br /> Kindly check your mail.
          </span>
        )}
      </FormContainer>
      <SocialContainer>
        <SocialGroup href="mailto:info@onilconstruct.com" target="_blank">
          <IconDiv style={{ backgroundColor: "#069FCE" }}>
            <FaEnvelope />
          </IconDiv>
          <IconP>INFO@ONILCONSTRUCT.COM.NG</IconP>
        </SocialGroup>
        <SocialGroup
          href="https://www.instagram.com/onil_construct?igsh=MjMyYTE5NHVveXl1&utm_source=qr"
          target="_blank"
        >
          <IconDiv style={{ backgroundColor: "#005D7A" }}>
            <FaInstagram />
          </IconDiv>
          <IconP>ONIL_CONSTRUCT</IconP>
        </SocialGroup>
        <SocialGroup href="tel:+2349063782562" target="_blank">
          <IconDiv style={{ backgroundColor: "#002936" }}>
            <FaPhone />
          </IconDiv>
          <IconP>+2349063782562</IconP>
        </SocialGroup>
      </SocialContainer>
    </Container>
  );
};

export default Contact;
