import styled from "styled-components";
import { device } from "../components/breakpoints.ts";

export const Chat = styled.a`
  display: flex;
  position: sticky;
  align-items: center;
  cursor: pointer;
  bottom: 50px;
  margin-right: 50px;
  align-self: flex-end;
  text-decoration: none;
  gap: 10px;
  z-index: 10;
  @media ${device.sm} {
    bottom: 30px;
  margin-right: 40px;
  }
`;

export const ChatText = styled.div`
  background-color: white;
  color: black;
  font-size: 10px;
  padding: 0 10px;
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 5px;
`;
export const Walogo = styled.img`
  width: 40px;
`;
